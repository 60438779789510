* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
::-webkit-scrollbar {
  width: 3px;
}

/* Change the color of the scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f1f1f111; /* Change this to your desired gray color */
}

/* Change the color of the scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #888888b2; /* Change this to your desired gray color */
}
