.categoriBox {
  width: 100%;
  min-width: 327px;
  height: 100%;
  margin-top: 30px;
}

.lineGraphBoxSAT {
  width: 100%;
  height: 58%;
  margin-bottom: 70px;
}

.lineGraphBoxSAT canvas {
  width: 100% !important;
  height: 100% !important;
}

.spotGraphBoxSAT {
  width: 100%;
}

.CategoriesItemBox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}

.CategoriCard {
  width: 158px;
  height: 183px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  background: #ffffff0a;
  margin-top: 23px;
}

.CCardIconBox {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.CCardIconBox p {
  color: #fff;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 5px;
}

.CCardMText {
  color: #fff;
  font-size: 22px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
}

.CCardSText {
  color: #fff;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
  margin-left: 5px;
}

.TText {
  width: 56px;
  height: 26px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.TText p {
  color: #50cd89;
  font-size: 11px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
  /* margin-left: 5px; */
}

.SATLBoxTable {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.gradient-border {
  border-width: 1px;
  border-style: solid;
  border-image: linear-gradient(to right, #15bffd8e, #a015fd93);
  border-image-slice: 1;
  border-radius: 8px;
}

.infoBox {
  width: 100%;
  height: 230px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  padding: 2px;
  background-image: linear-gradient(to right, #15bffd8e, #a015fd93);
}
.InfoInnerBox {
  width: 100%;
  height: 100%;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background: linear-gradient(85deg, #262135 16.42%, #19152b 97.35%);
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  padding: 8px;
}

.infoBoxHeader {
  color: #fff;
  text-align: center;
  font-size: 24px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: 36px;
  margin-bottom: 10px !important;
}

.infoBoxSubText {
  color: #ffffffd8;
  text-align: center;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  text-transform: capitalize;
}
.SAT_Box {
  flex-wrap: wrap;
}

@media only screen and (min-width: 768px) {
  .categoriBox {
    min-width: 100%;
    margin-top: 30px;
  }
  .spotGraphBoxSAT {
    width: 55%;
  }
  .lineGraphBoxSAT {
    width: 100%;
    height: 77%;
    margin-bottom: 20px;
  }

  .SATLBoxTable {
    flex-wrap: wrap;
  }

  .infoBox {
    max-width: 100%;
    width: 100%;
    height: 140px;
    margin: 0;
  }
}

@media only screen and (min-width: 1000px) {
  .SAT_Box {
    flex-wrap: inherit;
  }
  .categoriBox {
    width: 23%;
    min-width: 350px;
    height: 70vh;
    margin-left: 12px;
    margin-top: 0;
    overflow: scroll;
    overflow-x: hidden;
  }
  .spotGraphBoxSAT {
    width: 70%;
    margin-right: 12px;
  }
  .lineGraphBoxSAT {
    height: 58%;
    margin-bottom: 129px;
  }
}

@media only screen and (min-width: 1392px) {
  .SATLBoxTable {
    flex-direction: row;
    flex-wrap: inherit;
  }
  .infoBox {
    max-width: 341px;
    width: 33%;
    height: 316px;
    margin: 0;
    margin-left: 24px;
  }
}
