.adminInnerBox {
  display: block;
  height: auto !important;
  background-color: rgba(255, 255, 255, 0.05);
  backdrop-filter: blur(10px);
  padding: 3rem;
  border-radius: 10px;
  margin-bottom: 30px;
}
.adminBtnBox {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-around;
  margin: 20px 0 !important;
}
.admin_btn {
  width: 104px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.AInputBox {
  margin-top: 15px !important;
}
/* Tab view*/
@media only screen and (min-width: 768px) {
  .admin_btn {
    width: 70px;
  }
  .adminBtnBox {
    margin: 0 !important;
  }
  .adminInputBox {
    margin: 15px 0 !important;
  }
}
/* Desktop */
@media only screen and (min-width: 1000px) {
  .admin_btn {
    width: 104px;
  }
  .adminBtnBox {
    justify-content: space-evenly;
  }
}
